import React from 'react'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import Slide from 'react-reveal/Slide'

const PhilosophyAndMission = () => {
    return (
        <section className="grid grid-cols-1 bg-mainColor">
            <div className="py-4 md:py-10">
                <h3 className="sm:text-4xl text-2xl text-white my-4">Our Philosophy and Mission </h3>
                <hr className="horizontalLineWhite"/>

                <div className="container">
                    <div className="left">
                        <BusinessCenterIcon className="iconsWhite"/>
                    </div>
                    <Slide left cascade>
                        <div className="right">
                            <p className="text-left text-white sm:text-xl tracking-wide ">We use our experience in the field to identify gaps and create solutions that solve daily challenges and optimize your valuable time. 
                            </p>
                        </div>
                    </Slide>
                </div>
                <div className="container">
                    <div className="left">
                        <TrendingUpIcon className="iconsWhite"/>
                    </div>
                    <Slide left cascade>
                        <div className="right">
                            <p className="text-left text-white sm:text-xl tracking-wide">We leverage the latest technological advancements to build the next generation of products, increasing the quality while driving costs down for the customer.
                            </p>
                        </div>
                    </Slide>
                </div>
            </div>
        </section>
    )
}
export default PhilosophyAndMission