import React from 'react'
import Roll from 'react-reveal/Roll'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'

const Contact = () => {
    return (
        <section className="bg-mainColor p-2">
            <h3 className="sm:text-4xl text-2xl text-white my-4">Contact Us</h3>
            <hr className="horizontalLineWhite"/>
            <div className="grid grid-cols-1 md:grid-cols-3">
                <Roll cascade>
                    <div className="flex justify-center border-r">
                        <div className="">
                            <LocationOnIcon className="iconsWhite"/>
                            <p className="text-white text-xl">Address</p>
                            <address className="text-white">Aleea Lujerului, Bucharest, Romania</address>
                        </div>
                    </div>
                </Roll>
                <Roll cascade>
                    <div className="flex justify-center border-r">
                        <div className="">
                            <PhoneIcon className="iconsWhite"/>
                            <p className="text-white text-xl">Phone Number</p>
                            <p className="text-white"><a href="tel:+40 312297492">+40 312297492</a></p>
                        </div>
                    </div>
                </Roll>
                <Roll cascade>
                    <div className="flex justify-center">
                        <div className="">
                            <EmailIcon className="iconsWhite"/>
                            <p className="text-white text-xl">Email</p>
                            <p><a href="mailto:contact@rictatechnologies.com">contact@rictatechnologies.com</a></p>
                        </div>
                    </div>
                </Roll>
            </div>
        </section>
    )
}
export default Contact