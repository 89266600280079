import React from 'react'
//import RictaLogo from '../../images/rictaLogo.svg'
import Fade from 'react-reveal/Fade'

const Introduction = () => {
    return (
        <header className=" bg-no-repeat bg-center bg-cover resize">
            <div className=" py-28 md:py-40 bg-opacity-50 bg-black flex items-center justify-center" style={{background:'rgba(0,0,0,0.5)'}}>
                <div className="mx-2 text-center">
                    {/* <Fade left>
                        <img src={RictaLogo} className="rictaLogo"/>
                    </Fade> */}
                    <Fade left>
                        <h1 className="text-gray-100 whitespace-normal break-words mt-14 font-bold text-xl md:text-3xl lg:text-4xl">
                A Software Boutique company with focus on developing new browsing solutions for <br/> Forensic specialists and OSINT analysts.
                        </h1>
                    </Fade>
                </div>
            </div>
        </header>
    )

}
export default Introduction