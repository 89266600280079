import React from 'react'
import ForsintLogo from '../../images/FORSINT-RoundLogo.svg'
import BricoBrowserLogo from '../../images/social-media-3.svg'
import Pulse from 'react-reveal/Pulse'

const Products = () => {
    return (
        <section className="products">
            <h3 className="sm:text-4xl text-2xl text-white pt-4">Our products</h3>
            <h3 className="sm:text-xl text-lg text-white mt-4">Some of our Products developed and under development:</h3>
            <hr className="horizontalLineWhite"/>
            <div className="mainProducts">
                <Pulse>
                    <div className="firstProduct">
                        <h5>BricoBrowser</h5>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                            <a className="m-auto" href="" title="BricoBrowser"><img className="productsLogo" src={BricoBrowserLogo}/></a>
                            <div className="text-textColor tracking-wide text-center md:text-left sm:p-4 md:p-0">The Specialized Browser with superpowers:
                                Multi Screens, Multi Proxies, Multi Workspaces
                            </div>
                        </div>
                    </div>
                </Pulse>
                <Pulse>
                    <div className="firstProduct">
                        <h5>Forsint</h5>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-3 md:p-3">
                            <a className="m-auto" href="" title="Forsint"><img className="productsLogo" src={ForsintLogo}/></a>
                            <div className="text-textColor text-center md:text-left sm:p-4 md:p-0 tracking-wide">An innovative blockchain native platform for Online Forensics and OSINT Information collection!</div>
                        </div>
                    </div>
                </Pulse>
            </div>
        </section>
    )

}
export default Products