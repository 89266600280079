import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import GavelIcon from '@material-ui/icons/Gavel'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import WebIcon from '@material-ui/icons/Web'
import Fade from 'react-reveal/Fade'


const Customers = () => {
    return (
        <section className="customers">
            <h3 className="sm:text-4xl text-2xl text-mainColor my-4">Some of Our Customers</h3>
            <hr className="horizontalLine"/>
            <div className="mainCustomers">
                <Fade>
                    <div className="first">
                        <WebIcon className="iconsProducts"/>
                        <h4>Smart Browser Users</h4>
                        <p>Customers that want superior browser experience by combining increased productivity through organization tools and higher security / anonymity.</p>
                    </div>
                </Fade>
                <Fade>
                    <div className="two">
                        <SearchIcon className="iconsProductsWhite"/>
                        <h4>Forensic Users</h4>
                        <p>Forensic specialists that require  to acquire digital forensic evidence with focus on maintaining chain of custody.</p>
                    </div>
                </Fade>
                <Fade>
                    <div className="first">
                        <FingerprintIcon className="iconsProducts"/>
                        <h4>OSINT Users</h4>
                        <p>Open source intelligence analysts investigating, collecting and evaluating digital evidence. </p>
                    </div>
                </Fade>
                <Fade>
                    <div className="two">
                        <PeopleAltIcon className="iconsProductsWhite"/>
                        <h4>Lawyers</h4>
                        <p>In case of brand infringement, reputation damage and anti- counterfeit cases that requires online supporting evidence.</p>
                    </div>
                </Fade>
                <Fade>
                    <div className="first">
                        <GavelIcon className="iconsProducts"/>
                        <h4>Law Enforcement</h4>
                        <p>To investigate, survey or perform digital analysis that require as supporting evidence some online content.</p>
                    </div>
                </Fade>
                <Fade>
                    <div className="two">
                        <SupervisorAccountIcon className="iconsProductsWhite"/>
                        <h4>Investigators</h4>
                        <p>By companies investigating system events, fraud or suspicious online activity linked to their employees.</p>
                    </div>
                </Fade>
            </div>
        </section>
    )
}
export default Customers